<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="昵称：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入用户名" clearable></el-input>
          </el-form-item>
          <el-form-item label="订单状态：">
            <el-select v-model="listQuery.orderStatus" placeholder="全部" clearable class="input-width">
              <el-option v-for="item in orderOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="userPayTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <!--        <el-table-column type="selection" width="60" align="center"></el-table-column>-->
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="用户ID"  align="center" width="180">
          <template #default="scope">{{scope.row.user_id}}</template>
        </el-table-column>
        <el-table-column label="昵称"  align="center">
          <template #default="scope">{{scope.row.nick_name}}</template>
        </el-table-column>
        <el-table-column label="文件信息" align="center">
          <template #default="scope">{{scope.row.file_name}}</template>
        </el-table-column>
        <el-table-column label="订单编号" align="center">
          <template #default="scope">{{scope.row.tradeno}}</template>
        </el-table-column>
        <el-table-column label="订单状态" align="center">
          <template #default="scope">{{scope.row.payflag}}</template>
        </el-table-column>
        <el-table-column label="支付金额" align="center">
          <template #default="scope">{{scope.row.amount}}</template>
        </el-table-column>
        <el-table-column label="支付时间" align="center">
          <template #default="scope">{{scope.row.createtime}}</template>
        </el-table-column>

        <el-table-column label="操作" width="250" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="handleOrderClose(scope.row)">订单关闭</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>

import {buyDeleteBatch, buyDelete, buyList, handleOrderClose} from "@/api/userBuy";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
  orderStatus: null
};

const defaultOrderOptions = [
  {
    label: '未支付',
    value: 0
  },
  {
    label: '已支付',
    value: 1
  },
  {
    label: '已关闭',
    value: 2
  }
];

export default {
  name: 'FileBuy',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      orderOptions: Object.assign({}, defaultOrderOptions),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": row.id,
        };
        buyDelete(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
        };
        buyDeleteBatch(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //订单关闭
    handleOrderClose(row){
      this.$confirm('确定关闭订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "trade_no": row.tradeno,
          "user_id": row.user_id,
        };
        handleOrderClose(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "订单关闭成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },

    //列表
    getTableList() {
      this.listLoading = true;
      buyList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      })
    },

  }
}
</script>
<style scoped>


</style>
