import request from '@/utils/request';

//列表
export function buyList(data) {
  return request({
    url : '/user/file/buyList.php',
    method : 'post',
    data : data
  })
}

//删除
export function buyDelete(data) {
  return request({
    url : '/user/file/buyDelete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function buyDeleteBatch(data) {
  return request({
    url : '/user/file/buyDeleteBatch.php',
    method : 'post',
    data : data
  })
}

//关闭订单
export function handleOrderClose(data) {
  return request({
    url : '/user/file/buyOrderClose.php',
    method : 'post',
    data : data
  })
}
